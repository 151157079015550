import { useCallback, useMemo, useRef, useState } from 'react'
import { isCancelledError, useMutation, useQueryClient } from '@tanstack/react-query'
import { useMount, usePrevious, useUnmount } from 'react-use'
import { getRouteApi } from '@tanstack/react-router'
import { IDLE, PENDING, REJECTED, RESOLVED } from 'types/status'
import getFilteredItems from 'pages/searches/[searchid]/index/functions/get-filtered-items.function'
import { BROADCAST_CHANNEL_KEY, BROADCAST_FETCH_FAILED } from 'types/others'
import { itemsQueries } from 'requests/queries'
import useNavigationStore from 'stores/navigation.store'
import useSearchStore from 'stores/search.store'
import runAfterRender from 'utils/others/run-after-render'
import router from 'pages/_app/app.router'
import getSearchQuery from 'pages/searches/[searchid]/index/functions/get-search-query.function'
import useBroadcastChannel from 'hooks/broadcast-channel.hook'
import useWakeLock from 'hooks/wake-lock.hook'
import useHashChange from 'hooks/hash-change.hook'
import type { SearchidSearchesContextType } from 'pages/searches/[searchid]/index/index.context'
import type { Status } from 'types/status'
import type { ScraperValues } from 'types/consts/scraper.const'
import type { MetaQuery } from 'requests/types/common'
import type { ItemType, SearchType } from 'requests/types/helpers'
import type { SearchItemsSearch } from 'pages/_app/app.router'
import type { ScraperAll } from 'types/types/scraper.type'

export interface UseIndexItemsParams
    extends Pick<SearchidSearchesContextType, 'scraperVisible' | 'searchUser' | 'scraperValuesEnable' | 'currentScraperValue'> {
    /** SearchId */
    searchId: SearchType['_id']
}

export interface UseIndexItemsReturns {
    /** RefetchItems */
    refetchItems: () => Promise<void>
    /** Rollback */
    rollback: (scraperValue?: UseIndexItemsParams['currentScraperValue']) => void
    /** UpdateOneItem */
    updateOneItem: (item: ItemType, scraperValue: ScraperValues) => void
    /** Status */
    tabsStatus: TabsStatus
    /** Items */
    tabsItems: TabsItems
    /** OnAfterSwipe */
    onAfterSwipe: (
        prevScraperValue: UseIndexItemsParams['currentScraperValue'],
        newScraperValue: UseIndexItemsParams['currentScraperValue'],
    ) => void
    /** Cancel all request */
    cancelAllRunningRequests: () => void
}

export type TabsItems = Record<UseIndexItemsParams['currentScraperValue'], Array<ItemType>>

type TabsStatus = Record<UseIndexItemsParams['currentScraperValue'], Status>

/**
 * Check if all items array are empty on a `TabsItems`
 * @param tabsItems TabsItems
 * @returns isItemsEmpty
 */
const isItemsEmpty = (tabsItems: TabsItems) => Object.keys(tabsItems).every(key => tabsItems[key as keyof TabsItems].length === 0)
/**
 * Check if all status are `PENDING` on a `tabsStatus`
 * @param tabsStatus TabsStatus
 * @returns isStatusPending
 */
const isStatusPending = (tabsStatus: TabsStatus) => Object.keys(tabsStatus).every(key => tabsStatus[key as keyof TabsItems] === PENDING)

const route = getRouteApi('/layout/searches/$searchid')

/**
 * UseIndexItems to handle items: api call, filters, etc.
 * @returns IndexItems
 */
export default function useIndexItems({
    currentScraperValue,
    scraperValuesEnable,
    searchUser,
    searchId,
    scraperVisible,
}: UseIndexItemsParams): UseIndexItemsReturns {
    const setItemsFound = useSearchStore(state => state.setItemsFound)
    const setLastSearchOn = useSearchStore(state => state.setLastSearchOn)
    const setAlert = useNavigationStore(state => state.setAlert)
    const { allowWakeLock, disableWakeLock } = useWakeLock()
    const queryClient = useQueryClient()
    const navigate = route.useNavigate()
    const filters = route.useSearch()

    const defaultItems = useMemo(
        () =>
            new Array(scraperValuesEnable.length)
                .fill({})
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .map((_, i) => scraperValuesEnable[i]!)
                .reduce((a, b) => ({ ...a, [b]: [] }), { default: [] as Array<ItemType> }),
        [scraperValuesEnable],
    ) as TabsItems
    const defaultStatus = useMemo(
        () =>
            new Array(scraperValuesEnable.length)
                .fill({})
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .map((_, i) => scraperValuesEnable[i]!)
                .reduce((a, b) => ({ ...a, [b]: PENDING }), { default: PENDING as Status }),
        [scraperValuesEnable],
    ) as TabsStatus

    /** Tabs content */
    const [tabsIniItems, setTabsIniItems] = useState<TabsItems>(defaultItems)
    const [tabsIniStatus, setTabsIniStatus] = useState<TabsStatus>(defaultStatus)

    /** To know if swiping is done */
    const isSwiping = useRef(false)
    /** To know if hash change because of a rollback */
    const isRollback = useRef(false)

    /** Store previous last search on Date, in case we need to rollback to it */
    const prevLastSearch = useRef<{
        /** Last search on Date */
        on: Date | null
        /** Last search query */
        query: SearchItemsSearch
    }>({ on: null, query: getSearchQuery() })
    /** Store previous last search failed on Date. Sent from SW */
    const prevLastSearchFailedOn = useRef<Date | null>(null)
    /** Keep in memory the previous items to keep filtered items on previous scraper, even if filters are cleared */
    const prevTabsItems = useRef<TabsItems>(tabsIniItems)

    /** True tabs items to be displayed */
    const tabsItems = useMemo<TabsItems>(() => {
        /** Items filter filtered */
        const tabsItemsFiltered = {
            ...prevTabsItems.current,
            [currentScraperValue]: [PENDING, IDLE].includes(tabsIniStatus[currentScraperValue])
                ? []
                : getFilteredItems({
                      items: tabsIniItems[currentScraperValue],
                      filters,
                  }),
        } as TabsItems

        // Update number of items found
        if (![PENDING, IDLE].includes(tabsIniStatus[currentScraperValue]) && currentScraperValue === scraperVisible) {
            runAfterRender(() => {
                if (!isSwiping.current) {
                    setItemsFound({
                        total: tabsIniItems[currentScraperValue].length,
                        current: tabsItemsFiltered[currentScraperValue].length,
                    })
                }
            })

            // Update search query at this point to be able to rollback if needed
            prevLastSearch.current.query = filters
        }

        // Update previous value
        prevTabsItems.current = tabsItemsFiltered

        return tabsItemsFiltered
    }, [currentScraperValue, tabsIniStatus, tabsIniItems, scraperVisible, filters, setItemsFound])

    /** Override value to force all indexes to have "PENDING" if not the current visible */
    const tabsStatus = useMemo(
        () =>
            Object.keys(tabsIniStatus).reduce(
                (prev, curr) => ({ ...prev, [curr]: scraperVisible !== curr ? PENDING : tabsIniStatus[curr] }),
                {} as TabsStatus,
            ),
        [scraperVisible, tabsIniStatus],
    )

    const { mutate: updateHistory } = useMutation({
        ...itemsQueries.updateHistory(),
        meta: {
            isGlobalErrorEnable: false,
        } as MetaQuery,
    })

    /** Cancel all request */
    const cancelAllRunningRequests = useCallback(() => {
        scraperValuesEnable.forEach(
            scraperValue =>
                void queryClient.cancelQueries({
                    queryKey: ['searches', searchId, 'scrapers', scraperValue, 'items'],
                    exact: true,
                }),
        )
    }, [queryClient, scraperValuesEnable, searchId])

    /**
     * Cleanup stuff after a fetch is done
     */
    const onAfterFetch = useCallback(
        ({
            scraperValue,
            status,
            items = [],
            lastSearchOn = null,
        }: {
            /** ScraperValue */
            scraperValue: UseIndexItemsParams['currentScraperValue']
            /** Status */
            status: Status
            /** Items */
            items?: Array<ItemType>
            /** LastSearchOn */
            lastSearchOn?: Date | null
        }) => {
            // Set the new date
            prevLastSearch.current.on = lastSearchOn
            setLastSearchOn(lastSearchOn)
            setItemsFound({
                total: status === REJECTED ? null : items.length,
                current:
                    status === REJECTED
                        ? null
                        : getFilteredItems({ items, filters: router.state.location.search as SearchItemsSearch }).length,
            })
            void disableWakeLock()

            if (
                status !== REJECTED &&
                scraperValue !== 'default' &&
                (searchUser?.[scraperValue] as ScraperAll | undefined)?.histories?.isEnable
            ) {
                updateHistory({
                    params: {
                        path: {
                            searchId,
                            scraperValue,
                        },
                    },
                    body: {
                        histories: {
                            elements: items.map(item => ({ url: item.url, price: item.price.base.original ?? null })),
                        },
                    },
                })
            }
        },
        [setLastSearchOn, setItemsFound, disableWakeLock, searchUser, updateHistory, searchId],
    )

    /** Rollback to previous search results */
    const rollback = useCallback(
        (scraperValue = currentScraperValue) => {
            isRollback.current = true
            cancelAllRunningRequests()
            void navigate({
                params: { searchid: searchId },
                search: prevLastSearch.current.query,
                hash: scraperValue !== 'default' ? scraperValue : undefined,
                replace: true,
                resetScroll: false,
            })
            setLastSearchOn(prevLastSearch.current.on)
            setItemsFound({
                total: tabsIniItems[scraperValue].length,
                current: getFilteredItems({ items: tabsIniItems[scraperValue], filters: router.state.location.search as SearchItemsSearch })
                    .length,
            })
            void disableWakeLock()
        },
        [cancelAllRunningRequests, currentScraperValue, disableWakeLock, navigate, searchId, setItemsFound, setLastSearchOn, tabsIniItems],
    )

    /** Fetch items */
    const fetchItems = useCallback(
        async (scraperValue: ScraperValues = currentScraperValue as ScraperValues) => {
            // Set is swiping if not fetching same index
            isSwiping.current = scraperValue !== currentScraperValue

            cancelAllRunningRequests()

            setLastSearchOn(null)
            setItemsFound({ total: null, current: null })
            setAlert(null)
            setTabsIniStatus(prevTabsIniStatus => {
                if (prevTabsIniStatus[scraperValue] === PENDING) {
                    return prevTabsIniStatus
                }
                return { ...prevTabsIniStatus, [scraperValue]: PENDING }
            })
            void allowWakeLock()
            prevLastSearchFailedOn.current = null

            try {
                const { items: data } = await queryClient.fetchQuery({
                    ...itemsQueries.getAll({ searchId, scraperValue }),
                })

                setTabsIniItems(prevTabsIniItems => ({ ...prevTabsIniItems, [scraperValue]: data }))
                setTabsIniStatus(prevTabsIniStatus => ({ ...prevTabsIniStatus, [scraperValue]: RESOLVED }))

                // If at this point the switch is done, clean up
                if (!isSwiping.current) {
                    onAfterFetch({
                        status: RESOLVED,
                        items: data,
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        lastSearchOn: prevLastSearchFailedOn.current ?? new Date(),
                        scraperValue,
                    })
                }
            } catch (error) {
                if (isCancelledError(error)) {
                    return
                }
                setTabsIniItems(prevTabsIniItems => {
                    // Do not update if same items
                    if (prevTabsIniItems[scraperValue].length !== 0) {
                        return { ...prevTabsIniItems, [scraperValue]: [] }
                    }
                    return prevTabsIniItems
                })
                setTabsIniStatus(prevTabsIniStatus => {
                    // Do not update if same status
                    if (prevTabsIniStatus[scraperValue] !== REJECTED) {
                        return { ...prevTabsIniStatus, [scraperValue]: REJECTED }
                    }
                    return prevTabsIniStatus
                })

                // If at this point the switch is done, clean up
                if (!isSwiping.current) {
                    onAfterFetch({ status: REJECTED, scraperValue, lastSearchOn: new Date() })
                }
            }
        },
        [
            currentScraperValue,
            cancelAllRunningRequests,
            setLastSearchOn,
            setItemsFound,
            setAlert,
            allowWakeLock,
            queryClient,
            searchId,
            onAfterFetch,
        ],
    )

    /** Update on item */
    const updateOneItem = useCallback<UseIndexItemsReturns['updateOneItem']>((item, scraperValue) => {
        setTabsIniItems((prevItems: TabsItems): TabsItems => {
            const prevItemsCopy = structuredClone(prevItems)

            const itemIndex = prevItemsCopy[scraperValue].findIndex(x => x.url === item.url)

            if (itemIndex < 0) {
                return prevItems
            }

            prevItemsCopy[scraperValue][itemIndex] = item

            return prevItemsCopy
        })
    }, [])

    // Clear items on switch end
    const onAfterSwipe = useCallback<UseIndexItemsReturns['onAfterSwipe']>(
        (prevScraperValue, newScraperValue) => {
            isSwiping.current = false

            setTabsIniItems(prevTabsIniItems => {
                // We do not update if already all others tabs are empty: it means we are still on same scraper
                if (isItemsEmpty(prevTabsIniItems)) {
                    return prevTabsIniItems
                }

                const newTabsIniItems: TabsItems = { ...defaultItems, [newScraperValue]: prevTabsIniItems[newScraperValue] }

                // Reset previous value
                prevTabsItems.current = newTabsIniItems

                return newTabsIniItems
            })
            setTabsIniStatus(prevTabsIniStatus => {
                // We do not update if already all others tabs are pending
                if (isStatusPending(prevTabsIniStatus)) {
                    return prevTabsIniStatus
                }

                const newTabsIniStatus: TabsStatus = { ...defaultStatus, [newScraperValue]: prevTabsIniStatus[newScraperValue] }

                // If status is currently RESOLVED/REJECTED AND it's a new index, clean up
                if ([RESOLVED, REJECTED].includes(newTabsIniStatus[newScraperValue]) && newScraperValue !== prevScraperValue) {
                    runAfterRender(() => {
                        onAfterFetch({
                            status: newTabsIniStatus[newScraperValue],
                            items: tabsIniItems[newScraperValue],
                            lastSearchOn: prevLastSearchFailedOn.current ?? new Date(),
                            scraperValue: newScraperValue,
                        })
                    })
                }

                return newTabsIniStatus
            })
        },
        [onAfterFetch, defaultItems, defaultStatus, tabsIniItems],
    )

    // Subscribe to Broadcast Channel to check if Fetch failed
    useBroadcastChannel({
        name: BROADCAST_CHANNEL_KEY,
        onMessage: useCallback(ev => {
            if (ev.data.type === BROADCAST_FETCH_FAILED) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                prevLastSearchFailedOn.current = ev.data.payload.date
            }
        }, []),
    })

    useHashChange(newHash => {
        const newRawScraperValue = newHash.replace('#', '') as ScraperValues

        if (scraperValuesEnable.includes(newRawScraperValue)) {
            if (!isRollback.current) {
                void fetchItems(newRawScraperValue)
            }
        }

        // In any case, at this point rollback will be done
        isRollback.current = false
    })

    useMount(() => {
        if (currentScraperValue !== 'default') {
            void fetchItems()
        }
    })

    // Clear on unmount
    useUnmount(() => {
        setLastSearchOn(null)
        setItemsFound({ total: null, current: null })
        void disableWakeLock()
        cancelAllRunningRequests()
    })

    const prevSearchId = usePrevious(searchId)

    // Case to reset items/status keys
    if (
        // If searchId change and objects are not already clear
        (prevSearchId && searchId !== prevSearchId && (!isItemsEmpty(tabsIniItems) || !isStatusPending(tabsIniStatus))) ||
        // Or if not same number of items keys enable (allow to check if switching areDisableScraperVisible)
        Object.keys(defaultItems).length !== Object.keys(tabsIniItems).length ||
        // Or if not same number of status keys enable (allow to check if switching areDisableScraperVisible)
        Object.keys(defaultStatus).length !== Object.keys(tabsIniStatus).length
    ) {
        prevTabsItems.current = defaultItems
        setTabsIniItems(defaultItems)
        setTabsIniStatus(defaultStatus)
    }

    return {
        refetchItems: useCallback(() => fetchItems(), [fetchItems]),
        rollback,
        updateOneItem,
        tabsStatus,
        tabsItems,
        onAfterSwipe,
        cancelAllRunningRequests,
    }
}
