import { useCallback } from 'react'
import { getRouteApi } from '@tanstack/react-router'
import { TABS_CONTAINER_CLASSNAME } from 'pages/searches/[searchid]/index/components/tabs/tabs.constant'
import getSearchQuery from 'pages/searches/[searchid]/index/functions/get-search-query.function'
import useSearchStore from 'stores/search.store'
import type { UseIndexItemsReturns } from 'pages/searches/[searchid]/index/hooks/items.hook'
import type { SearchidSearchesContextType } from 'pages/searches/[searchid]/index/index.context'

export interface UseIndexNavigationParams
    extends Pick<SearchidSearchesContextType, 'currentScraperValue' | 'scraperValuesEnable' | 'searchUser'>,
        Pick<UseIndexItemsReturns, 'cancelAllRunningRequests'> {}

export interface UseIndexNavigationReturns {
    /** GoToScraper */
    goToScraper: (newScraperValue: UseIndexNavigationParams['currentScraperValue']) => void
    /** GoToNext */
    goToNext: () => void
    /** GoToPrev */
    goToPrev: () => void
}

const route = getRouteApi('/layout/searches/$searchid')

/**
 * UseIndexNavigation to handle navigation between scrapers
 * @returns IndexNavigation
 */
export default function useIndexNavigation({
    currentScraperValue,
    scraperValuesEnable,
    searchUser,
    cancelAllRunningRequests,
}: UseIndexNavigationParams): UseIndexNavigationReturns {
    const navigate = route.useNavigate()
    const setItemsFound = useSearchStore(state => state.setItemsFound)
    const setLastSearchOn = useSearchStore(state => state.setLastSearchOn)
    const searchId = route.useParams({ select: params => params.searchid })

    const currentScraperIndex = scraperValuesEnable.findIndex(x => x === currentScraperValue)

    const goToScraper = useCallback<UseIndexNavigationReturns['goToScraper']>(
        newScraperValue => {
            if (newScraperValue !== 'default') {
                void navigate({
                    params: { searchid: searchId },
                    hash: newScraperValue,
                    search: getSearchQuery(searchUser?.[newScraperValue]),
                    replace: true,
                    resetScroll: false,
                })
            } else {
                void navigate({ hash: '', replace: true, resetScroll: false })
                cancelAllRunningRequests()
                setLastSearchOn(null)
                setItemsFound({ total: null, current: null })
            }
        },
        [navigate, searchId, searchUser, cancelAllRunningRequests, setLastSearchOn, setItemsFound],
    )

    const goToNext = useCallback(() => {
        if (currentScraperIndex + 1 <= scraperValuesEnable.length - 1) {
            // Go to Next
            goToScraper(scraperValuesEnable[currentScraperIndex + 1] ?? 'default')
        } else {
            // Go back to first
            goToScraper('default')
            document.querySelector(`.${TABS_CONTAINER_CLASSNAME}`)?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }, [currentScraperIndex, goToScraper, scraperValuesEnable])

    const goToPrev = useCallback(() => {
        if (currentScraperIndex - 1 >= -1) {
            // Go to prev
            goToScraper(scraperValuesEnable[currentScraperIndex - 1] ?? 'default')
        } else {
            // Got to last
            goToScraper(scraperValuesEnable[scraperValuesEnable.length - 1] ?? 'default')
            document.querySelector(`.${TABS_CONTAINER_CLASSNAME}`)?.scrollTo({ top: 0, left: 99999, behavior: 'smooth' })
        }
    }, [currentScraperIndex, goToScraper, scraperValuesEnable])

    return {
        goToNext,
        goToPrev,
        goToScraper,
    }
}
